* {
  padding: 0px;
  margin: 0px;;
}

.App {
  text-align: center;
  box-sizing: border-box;
}


.dark-app-container{
  background: #1b1f24; 
}


