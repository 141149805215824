.reference-dark{
    color: white;
  }  
  
  .reference{
    color: black;
  }

  .footer-container {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .dark-footer-container {
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1b1f24;
   
    
  }

 