.streamsource-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38vw;
    height: 8vh;
    letter-spacing: 2px;
    flex-wrap: wrap;  
}

.stream-info {
    color: white;
    font-size: 15px;
}

@media screen and (max-width: 553px) {

    .streamsource-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70vw;
        height: 8vh; 
        letter-spacing: 2px;
        flex-wrap: wrap;  
    }

    .stream-info {
        color: white;
        font-size: 13px;
    }

}