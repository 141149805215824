.search-input {
    width: 670px;
    height: 30px;
    border-radius: 13px;
    font-size: 25px;
    padding: 10px;
    outline: none;
    border: none;
    background: white;
    z-index: 2;
    position: absolute;  
}

.dark-search-input {
    width: 670px;
    color: white;
    height: 30px;
    border-radius: 13px;
    font-size: 25px;
    padding: 10px;
    outline: none;
    border: none;
    background: #1b1f24; 
    z-index: 2;
    position: absolute;
}


.search-border {
    background: linear-gradient(180deg, #FF0000 0%, #5C5ABE 100%);
    height: 60px;
    width: 700px;
    position: absolute;
    z-index: 1;
    border-radius: 16.5px;
}

.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px;
}

@media only screen and (max-width: 990px) { 

    .search-input {
        width: 500px;
        height: 25px;
        border-radius: 14px;
        font-size: 20px;
    }

    .dark-search-input {
        width: 500px;
        height: 25px;
        border-radius: 14px;
        font-size: 20px;
    }

    .search-border {
        height: 55px;
        width: 529px;
        border-radius: 17px;
    }

}

@media only screen and (max-width: 572px) {

    .search-input {
        width: 400px;
        height: 25px;
        border-radius: 14px;
        font-size: 20px;
    }

    .dark-search-input {
        width: 400px;
        height: 25px;
        border-radius: 14px;
        font-size: 20px;
    }

    .search-border {
        height: 55px;
        width: 430px;
        border-radius: 17px;
    }

}

@media only screen and (max-width: 390px) {

    .search-input {
        width: 330px;
        height: 25px;
        border-radius: 14px;
    }

    .dark-search-input {
        width: 330px;
        height: 25px;
        border-radius: 14px;
    }

    .search-border {
        height: 55px;
        width: 360px;
    }

    }