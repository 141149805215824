.slide {
  padding: 15;
  color: '#fff';
  display: none;
}

.slide1 {
  background: '#FEA900';
  height: 550px;
}

.slide2 {
  background: '#B3DC4A';
  height: 800px;
}

.circles-container {
  display: flex;
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 20px -4px;
}

.circle1 {
  height: 12px;
  width: 12px;;
  background-color: grey;
  border-radius: 50%;
  margin: 15px 25px;
}

.circle2 {
  height: 12px;
  width: 12px;;
  background-color: grey;
  border-radius: 50%;
  opacity: 0.4
}

.circle3 {
  height: 12px;
  width: 12px;;
  background-color: grey;
  border-radius: 50%;
  opacity: 0.4
}

.circle4 {
  height: 12px;
  width: 12px;;
  background-color: grey;
  border-radius: 50%;
  margin: 15px 25px;
  outline: black;
}

@media screen and (max-width: 553px) {
  
  .slide {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}