.home-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
    
}

.dark-home-container {
    background: #1b1f24;
    height: 80vh;
    display: flex;
    flex-direction: column;
}



@media only screen and (max-width: 960px) {

  .home-container {
    height: 150vh;
    
}

.dark-home-container {

    height: 150vh;
}
 
}

